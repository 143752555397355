import Banner from "../../sections/common/banner";
import SectionWhatWeDo1 from "../../sections/home/index/section-what-we-do1";
import SectionAchivements1 from "../../sections/home/index2/section-achivements1";
import SectionTestimonials1 from "../../sections/home/index/section-testimonials1";
import SectionBlogs1 from "../../sections/home/index/section-blogs1";
import SectionCompanyApproch1 from "../../sections/home/index/section-company-approch1";
import { data } from "../../../globals/data/privacy-policy";

export default function PrivacyPolicy() {
  return (
    <>
      {/* <Banner _data={data.banner} /> */}
      <div className="privacy-policy-container">
      <div className="nature">
          <h1>Nature of Business</h1>

          <p>We use the payment gateway only for our on-demand services. Links of the on-demand services are provided in the footer. </p>
          <h3>Customer App</h3>
          <p>
            <ul>
              <li>
                The Customer App allows users to book rides seamlessly,
                selecting from available electric vehicles, including
                three-wheelers (3W) and four-wheelers (4W) based on real-time
                availability.
              </li>
              <li>
                Customers can choose the most suitable vehicle for their
                logistics needs, ensuring a hassle-free and eco-friendly
                transportation experience
              </li>
              <li>
                Once the ride is complete, the customer will need to make the
                payment.
              </li>
              <li>
                Customers can check their ride history, allowing them to review
                past bookings, payments, and trip details for better tracking
                and convenience.
              </li>
            </ul>
          </p>
          <h3>Driver App</h3>
          <p>
            <ul>
              <li>
                The Driver App is designed to help EV drivers efficiently manage
                ride requests. Once a customer books a ride, nearby verified
                drivers receive notifications and can accept the request through
                the app.
              </li>
            </ul>
          </p>

          <p>
            EV-based logistics company operates within the transportation and
            logistics industry, specializing in sustainable and eco-friendly
            mobility solutions. The business focuses on providing efficient and
            cost-effective logistics services using electric vehicles (EVs),
            catering to both B2B and B2C markets. Key offerings include parcel
            and freight delivery, last-mile logistics, and EV fleet management,
            helping businesses reduce their carbon footprint while optimizing
            supply chain operations. By leveraging advanced technology such as
            real-time tracking, the company enhances operational efficiency and
            customer satisfaction. Additionally, the business benefits from
            lower operational costs due to reduced fuel and maintenance
            expenses, while also aligning with global sustainability goals and
            government incentives promoting green transportation. With a strong
            commitment to innovation and environmental responsibility, the
            company is positioned as a leader in the future of logistics.
          </p>

          <h2>PRICING</h2>
          <p>
            Price Range: ₹100 - ₹10,000 depending on multiple factors,
            including:
            <ul>
              <li>
                Distance (KM-Based Pricing): The cost varies depending on the
                total kilometers covered.
              </li>
              <li>
                Location & Accessibility: Prices may differ based on urban,
                rural, or restricted areas.
              </li>
              <li>
                Vehicle Type: Charges vary for different vehicle categories
                (e.g., 3-Wheeler,4-Wheeler).
              </li>
              <li>
                Additional Services: Extra charges may apply for packaging,
                loading/unloading, or express delivery options.
              </li>
            </ul>
          </p>
        </div>
        <h1 className="heading">Privacy Policy</h1>
        <p>
          At Pickkup, we recognize the need to protect the privacy of the
          personal information we collect or you provide to us when you access
          and use our website (https://www.pickkup.io) or any linked websites
          under the banner of Pickkup (the Site). We make every effort to ensure
          your information is protected and remains private. As the owner and
          operator of our Service, website at Pickkup (the “Website”), and a
          mobile application Pickkup (the “Mobile App”), together hereafter
          referred to in this Privacy Policy as “Pickkup Driver or Customer”,
          we have provided this Privacy Policy to explain
          how we collect, use, share and protect information about the users of
          our Service
        </p>
        <p>
          We have adhered to this privacy policy (the Privacy Policy), which
          defines, among other things, the type of information that will be
          collected, the use of the collected information, and the user rights
          with regard to the collected information. By accessing the Site, you
          consent to the collection and use of information by Pickkup, but only
          to the extent described herein. If you wish to revoke your consent,
          you may do so in line with the provisions of clause 5.
        </p>

        <h2>1. Terms of Use</h2>
        <p>
          For this Privacy Policy, the data controller of personal data is
          Pickkup and can be reached at contact@pickkup.io. A data controller
          means the natural or legal person who (either alone or jointly or in
          common with other persons) determines the purposes for which, and how,
          any personal information is processed. This Privacy Policy is
          incorporated into and forms part of the Terms of Use, which outlines
          the terms and conditions you agree to when accessing and using the
          Site.
        </p>

        <h2>2. Age Restrictions</h2>
        <p>
          The Website is intended for people who are 18 years and above. If
          Pickkup comes across individuals who are under the age of 18, they
          will be removed from the platform without notice.
        </p>

        <h2>3. Information/Data Management & Protection Policy</h2>
        <p>
          We are accountable for the information in our custody and when we
          collect or use your information, we will utilize commercially
          reasonable safeguards to ensure its protection in compliance with the
          General Data Protection Regulation (GDPR).
          <ul>
            <li>
              Please refer to the Data Handling Policy describing the process of
              updating, archiving, limiting or managing access of the
              information in our custody, which is directly/indirectly refers to
              your individual credentials.
            </li>
            <li>
              Should any breach of your Personal Information may occur, we will
              follow the escalation matrix as described in the Data Handling
              Policy, as soon as we acknowledge receipt of any such information,
              notification or alert.
            </li>
            <li>
              In case of a security breach please follow the escalation matrix
              as described in clause 6 of the Data Handling Policy.
            </li>
          </ul>
        </p>

        <h2>4. Collection of Information</h2>
        <p>
          Pickkup collects information about you when you use our Website or App
          to access our services, and other online services (collectively, the
          “Services”).
        </p>
        <p>
          We are accountable for the information in our custody and when we
          collect or use your information, we will utilize commercially
          reasonable safeguards to ensure its protection.
        </p>
        <p>
          We collect information you provide directly to us, such as when you
          request information, create or modify your personal account, request
          Services, order a subscription to our Services, complete a Pickkup
          form, survey, questionnaire or application, contact customer support,
          make a purchase, join or enroll for an event or otherwise communicate
          with us in any manner. This information may include, without
          limitation: name, date of birth, e-mail address, phone number, profile
          photograph, credit card or other method of payment information, or any
          other personal information you choose to provide.
        </p>
        <p>
          We gather information at different points of engagement online or in
          person to facilitate use by customers.
        </p>
        <p>Two kinds of information are collected:</p>
        <ul>
          <li>
            <h4>Personal Information :</h4>
          </li>
          <p>
            To access and use the Site or some services provided therein, you
            must first provide personal information that will allow you to be
            identified (Personal Information). This information will not be
            collected without your consent. The purposes of the collection are
            as follows:
          </p>
          <ul>
            <li>To identify the user and build a relationship </li>
            <li>To facilitate services on our Site</li>
            <li>To provide services from our affiliated service providers</li>
            <li>To support services and rectify problems with your service</li>
            <li>To understand your requirements visit the Site</li>
            <li>
              To inform you of the changes to our services or products or
              promotions
            </li>
            <li>
              To identify if he/she is the same person using the
              services/platform
            </li>
          </ul>
          <li>
            <h4>Non-Personal Information :</h4>
          </li>
          <p>
            Upon accessing the Website, certain non-personal information will be
            automatically collected without your knowledge or consent, such as
            your IP address and the referring Website. We use such Information
            to examine our traffic and to view how customers use the Website.
            This type of information will not allow you to be identified
            personally, i.e., we use cookies, which contain only certain
            relevant information. You can instruct your computer to disallow
            cookies through your web browser. If you do choose to disallow
            cookies, your experience on the Site may be diminished or limited.
          </p>
          <ul>
            <li>
              We acknowledge not using your Personal Information for any other
              purposes without your consent and clarify that Personal
              Information will be gathered to the extent necessary for the
              requisite business verification purposes. The Personal Information
              we collect will vary depending on how you are using the Site,
              which may include, without limitation, address, phone number,
              email, credit card information, profile, bank account information,
              etc., for business, financial or non-financial transactions and
              purposes.
            </li>
            <li>
              Purpose of collecting such data or Personal Information may vary
              from functions at Pickkup, like, Human Resources Management,
              Finance & Operations, Business Development, Marketing, etc., or,
              in execution of a mutually agreed scope of work assigned to
              Pickkup.
            </li>
          </ul>
        </ul>

        <h2>5. Sharing Data</h2>
        <p>
          We will not sell, rent or disclose to outside parties the information
          we collect, save and except that we may share the collected
          information with other parties as follows:
        </p>
        <ul>
          <li>
            <h4>Partner Service Providers :</h4>
          </li>
          <p>
            We have inked agreements with various partner service providers to
            facilitate the functioning of the Site, with whom we will share
            collected information. For instance, your credit card information
            will be shared with the credit card service provider to process your
            purchase. All service providers that we have engaged have a
            reasonable level of privacy protection as we have.
          </p>
          <li>
            <h4>Requirements of law :</h4>
          </li>
          <p>
            We will share information about you where required by a demand from
            a duly authorized government authority or where we are required to
            do so by a court of competent authority.
          </p>
          <li>
            <h4>Analysis :</h4>
          </li>
          <p>
            Non-Personal Information may be aggregated and shared with third
            parties for advertising or marketing purposes and not personal
            information.
          </p>
        </ul>

        <h2>6. Financial Transactions</h2>
        <p>
          During negotiations in connection with any transactions such as, any
          merger, sale of company assets, financing or acquisition, or other
          similar situation; collected information may be disclosed or
          transferred as one of our business assets.
        </p>

        <h2>7. Delete Data</h2>
        <p>
          The user can request data deletion by clicking the link here. The app
          will be deleted within 3 working days after receiving the data
          deletion request.
        </p>

        <h2>8. External Links</h2>
        <p>
          The Site may contain third party links and references to other
          websites. We are not responsible for the collection, use and
          disclosure of information, or the privacy practices of such Websites,
          and we expressly disclaim any liability relating to them.
        </p>

        <h2>9. International Transfer :</h2>
        <p>
          Your information may be transferred to and maintained on servers
          located outside of your jurisdiction, where privacy laws may vary.
          Your consent to this Privacy Policy represents your consent to any
          such transfer.
        </p>

        <h2>10. HIPAA Disclaimer :</h2>
        <p>
          HIPAA is a set of laws that applies to healthcare information. We are
          not a healthcare provider so those laws don’t apply to us
        </p>
        <p>The purposes of the collection are as follows:</p>
        <p>
          Pickkup is not a healthcare provider and does not provide medical
          care, advice, diagnosis, treatment or services and your use of our
          Services does not create a patient/physician relationship. As such, we
          specifically disclaim any representation or warranty that Pickkup
          Services, as offered, comply with the federal Health Insurance
          Portability and Accountability Act (“HIPAA”). Pickkup only maintains
          the minimum data necessary to provide our services, does not store or
          maintain your protected health information (PHI) and is not compliant.
        </p>

        <h2>11. Contact Us</h2>
        <p>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us at contact@pickkup.io, +91 7068290682.
        </p>
        <h2>12. Payment processing :</h2>
        <p>
          We use Phonepe to manage payment processing. We don’t store any of
          your financial information.
        </p>
        <h2>13. Payment Information :</h2>
        <p>
          Pickkup uses a secure third party payment processing gateway to
          collect, store and process payment information. That information is
          provided directly to our third party payment processors whose use of
          your personal information is governed by their Privacy Policy. Pickkup
          does not store this information on our Website or servers.
        </p>
      </div>
    </>
  );
}
