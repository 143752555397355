export default function RefundPolicy() {
  return (
    <>
      {/* <Banner _data={data.banner} /> */}
      <div className="privacy-policy-container">
        <div className="nature">
          <h1>Nature of Business</h1>
          <p>We use the payment gateway only for our on-demand services. Links of the on-demand services are provided in the footer. </p>

          <h3>Customer App</h3>
          <p>
            <ul>
              <li>
                The Customer App allows users to book rides seamlessly,
                selecting from available electric vehicles, including
                three-wheelers (3W) and four-wheelers (4W) based on real-time
                availability.
              </li>
              <li>
                Customers can choose the most suitable vehicle for their
                logistics needs, ensuring a hassle-free and eco-friendly
                transportation experience
              </li>
              <li>
                Once the ride is complete, the customer will need to make the
                payment.
              </li>
              <li>
                Customers can check their ride history, allowing them to review
                past bookings, payments, and trip details for better tracking
                and convenience.
              </li>
            </ul>
          </p>
          <h3>Driver App</h3>
          <p>
            <ul>
              <li>
                The Driver App is designed to help EV drivers efficiently manage
                ride requests. Once a customer books a ride, nearby verified
                drivers receive notifications and can accept the request through
                the app.
              </li>
            </ul>
          </p>

          <p>
            EV-based logistics company operates within the transportation and
            logistics industry, specializing in sustainable and eco-friendly
            mobility solutions. The business focuses on providing efficient and
            cost-effective logistics services using electric vehicles (EVs),
            catering to both B2B and B2C markets. Key offerings include parcel
            and freight delivery, last-mile logistics, and EV fleet management,
            helping businesses reduce their carbon footprint while optimizing
            supply chain operations. By leveraging advanced technology such as
            real-time tracking, the company enhances operational efficiency and
            customer satisfaction. Additionally, the business benefits from
            lower operational costs due to reduced fuel and maintenance
            expenses, while also aligning with global sustainability goals and
            government incentives promoting green transportation. With a strong
            commitment to innovation and environmental responsibility, the
            company is positioned as a leader in the future of logistics.
          </p>

          <h2>PRICING</h2>
          <p>
            Price Range: ₹100 - ₹10,000 depending on multiple factors,
            including:
            <ul>
              <li>
                Distance (KM-Based Pricing): The cost varies depending on the
                total kilometers covered.
              </li>
              <li>
                Location & Accessibility: Prices may differ based on urban,
                rural, or restricted areas.
              </li>
              <li>
                Vehicle Type: Charges vary for different vehicle categories
                (e.g., 3-Wheeler,4-Wheeler).
              </li>
              <li>
                Additional Services: Extra charges may apply for packaging,
                loading/unloading, or express delivery options.
              </li>
            </ul>
          </p>
        </div>
        <h1 className="heading">Refund Policy</h1>

        <h2>1. Eligibility for Refunds</h2>
        <p>
          We strive to provide seamless and reliable logistics services.
          However, refunds may be issued under the following circumstances:
          <ul>
            <li>
              Service cancellation by the company due to unforeseen
              circumstances.
            </li>
            <li>
              Failed or undelivered shipments due to our operational issues.
            </li>
            <li>Excess payment or duplicate transactions.</li>
          </ul>
        </p>
        <h2>2. Non-Refundable Cases</h2>
        <p>
          Refunds will not be applicable in the following situations:
          <ul>
            <li>
              Delay caused by external factors (weather, traffic, government
              regulations, etc.).
            </li>
            <li>
              Incorrect details provided by the customer (wrong address, contact
              number, etc.).
            </li>
            <li>
              Cancellation of the order after the shipment has been dispatched.
            </li>
            <li>Customer unavailability at the delivery location.</li>
          </ul>
        </p>
        <h2>3. Refund Process</h2>
        <p>
          <ul>
            <li>
              Refund requests must be made within 4-6 days from the date of the
              issue.
            </li>
            <li>
              Customers must provide proof of payment and a valid reason for the
              refund request.
            </li>
            <li>
              All refund requests can be initiated by contacting our support
              team at <u>contact@pickkup.io</u>
            </li>
          </ul>
        </p>
        <h2>4. Refund Timeline</h2>
        <p>
          <ul>
            <li>
              Approved refunds will be processed within 4-6 business days.
            </li>
            <li>
              Approved refunds will be credited to the user's account within 5-7
              business days.
            </li>
            <li>
              Refunds will be credited to the original payment method.
            </li>
          </ul>
        </p>

        <h2>5. Contact Us</h2>
        <p>
          For any refund-related queries, please reach out to us at 70682-90682.
        </p>
      </div>
    </>
  );
}
