import { NavLink } from "react-router-dom";
import { global } from "../../../../globals/constants";

export default function CoorporateClient() {
    const logos = [
        global.logo + "1.png",
        global.logo + "2.png",
        global.logo + "3.png",
        global.logo + "4.png",
        global.logo + "5.png",
        global.logo + "6.png",
        global.logo + "7.png",
        global.logo + "8.png",
        global.logo + "9.png",
        global.logo + "10.png",
        global.logo + "11.png",
        global.logo + "12.png",
        global.logo + "1.png",
        global.logo + "2.png",
        global.logo + "3.png",
        global.logo + "4.png",
        global.logo + "5.png",
        global.logo + "6.png",
        global.logo + "7.png",
        global.logo + "8.png",
        global.logo + "9.png",
        global.logo + "10.png",
        global.logo + "11.png",
        global.logo + "12.png",
    ];

    return (
        <div className="coorporate-client">
            <div className="container">
                <div className="section-content">
                    <div className="owl-carousel home-client-carousel3 owl-btn-vertical-center">
                        {logos.map((logo, index) => (
                            <div key={index} className="item">
                                <div className="ow-client-logo">
                                    <div className="client-logo client-logo-media">
                                        <NavLink><img src={logo} alt={`Client Logo ${index + 1}`} /></NavLink>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
