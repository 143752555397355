export default function SectionTrackk({ _data }) {
  return (
    <div
      className="section-full p-t120 p-b90 tw-booking-area tw-booking-area-bg-wrap"
      // style={{ backgroundImage: `url(${_data.background})` }}
    >
      <div className="container">
        <div className="tw-booking-section">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="tw-booking-media">
                <div className="media">
                  <div className="wt-small-separator site-text-primary">
                    <div>{_data.title}</div>
                  </div>
                  {/* <h2 className="wt-title">{_data.subtitle}</h2> */}
                  <h2 className="wt-title">{_data.subtitle}</h2>
                  <div className="tw-booking-images d-flex gap-3 mt-4">
                    <img
                      src="/assets/images/login.png"
                      alt="Image 1"
                      className="img-fluid"
                      style={{ width: "30%" }}
                    />
                    <img
                      src="/assets/images/detail.png"
                      alt="Image 2"
                      className="img-fluid"
                      style={{ width: "30%" }}
                    />
                    <img
                      src="/assets/images/device.png"
                      alt="Image 3"
                      className="img-fluid"
                      style={{ width: "30%" }}
                    />
                  </div>

                  <p className="section-head-text">{_data.description}</p>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 text-center">
              <img
                className="map-view img-fluid m-t70"
                src="/assets/images/trackk7.png"
                alt=""
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
