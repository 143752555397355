import { global } from "../../../../globals/constants";
import WarehouseImage from "../Warehouse/warehouseImage";
import CoorporateClient from "./coorporateImage";

// http://192.168.0.107:3000
export default function Coorporates(_data) {
  return (
    <div>
      {/* <Banner _data={data.banner} /> */}

      <div className="warehouse-container">
        <h1 className="heading service">Corporates</h1>
        <p>
          We are a last-mile delivery solution provider through 3w and 4w EVs.
          We provide services to corporates, who use our vehicles to manage
          their deliveries at different locations as needed. <b>Trackk</b> is
          used to manage Fleet and the whole Workforce to track each and every
          activity
        </p>
        <b>We do not use any kind of payment gateway in this service.</b>
      </div>
      <WarehouseImage />

      <div className="warehouse-container">
        <h1 className="heading">Our Corporate Clients</h1>
        <CoorporateClient
          
        />
      </div>
    </div>
  );
}
