import Banner from "../../../sections/common/banner";
import SectionWhatWeDo1 from "../../../sections/home/index/section-what-we-do1";
import SectionAchivements1 from "../../../sections/home/index2/section-achivements1";
import SectionTestimonials1 from "../../../sections/home/index/section-testimonials1";
import SectionBlogs1 from "../../../sections/home/index/section-blogs1";
import SectionCompanyApproch1 from "../../../sections/home/index/section-company-approch1";
import { data } from "../../../../globals/data/terms-conditions";
import WarehouseImage from "./warehouseImage";

export default function Warehouse() {
  return (
    <>
      {/* <Banner _data={data.banner} /> */}

      <div className="warehouse-container">
        <h1 className="heading service">Warehouse</h1>
        <p>
          We are a last-mile delivery solution provider through 3w and 4w EVs.
          In warehousing, we provide services to ensure efficient delivery of
          packages through 3W and 4W vehicles. To manage Fleet, Orders, and
          Workforce we offer <b>Trackk</b>, a robust tracking app that enables seamless
          order management, fleet supervision, real-time vehicle tracking, and
          vehicle audits. Our technology-driven approach ensures transparency,
          efficiency, and reliability at every step of the supply chain
        </p>
        <b>We do not use any kind of payment gateway for this service.</b>
      </div>
      <WarehouseImage />
    </>
  );
}
