import { NavLink } from "react-router-dom";

export default function OnDemand() {
  return (
    <>
      <div className="warehouse-container">
        <h1 className="heading service">On Demand</h1>
        <p>
          On Demand Platform for B2B customers assisting in their deliveries
          through 100% EV Fleet around with 600+ happy customers. For the
          on-demand service, we have a <b>Driver app </b> and a{" "}
          <b>Customer app </b>.
        </p>

        {/* First section: Image on left, Text on right */}
        <div className="content-section">
          <div className="text-container">
            <div className="heading-container">
              <h2>Driver App</h2>
              <a
                href="https://play.google.com/store/apps/details?id=com.pickkup.drivers"
                target="_blank"
              >
                <img
                  src="../assets/images/googlePlay.png"
                  alt="Driver App Heading"
                  className="heading-image"
                />
              </a>
            </div>
            <div className="image-row">
              <img
                src="../assets/images/on-demand/driver5.png"
                alt="Driver App 1"
              />
              <img
                src="../assets/images/on-demand/driver1.png"
                alt="Driver App 1"
              />
              <img
                src="../assets/images/on-demand/driver2.png"
                alt="Driver App 1"
              />
              <img
                src="../assets/images/on-demand/driver3.png"
                alt="Driver App 1"
              />
              <img
                src="../assets/images/on-demand/driver4.png"
                alt="Driver App 1"
              />
            </div>
            <p>
              The driver app enables drivers to accept and complete ride or
              delivery requests efficiently.
            </p>
          </div>
        </div>

        {/* Second section: Text on left, Image on right */}
        <div className="content-section reverse">
          <div className="text-container">
          <div className="heading-container">
              <h2>Customer App</h2>
              <a
                href="https://play.google.com/store/apps/details?id=com.pickkup.customer"
                target="_blank"
              >
                <img
                  src="../assets/images/googlePlay.png"
                  alt="Driver App Heading"
                  className="heading-image"
                />
              </a>
            </div>
            <div className="image-row">
            <img
                src="../assets/images/on-demand/cust1.png"
                alt="Driver App 1"
              />
              
             
              <img
                src="../assets/images/on-demand/cust2.png"
                alt="Driver App 1"
              />
              <img
                src="../assets/images/on-demand/cust4.png"
                alt="Driver App 1"
              />
              <img
                src="../assets/images/on-demand/cust3.png"
                alt="Driver App 1"
              />
              
              <img
                src="../assets/images/on-demand/cust5.png"
                alt="Driver App 1"
              />
            </div>
            <p>
              We are an on-demand transportation and logistics provider. Use our
              app to book and manage pickups and deliveries of your goods at
              your fingertips. We have a wide range of vehicles, and you can
              select the fleet size that fits your goods and saves you money.
              <b> Customers can book rides from this app only.</b>
            </p>
          </div>
        </div>
        <b>We use a payment gateway for this service only.</b>
      </div>
    </>
  );
}
